import { getDeliveryOutletsList } from "@/api/order/create"
import { getDeliveryOutletsList as outletGetDeliveryOutletsList } from "@/api/outlets"

// 客服下单： 地址 --> 能配送的网点（含配送范围）
export async function searchShopDeliveryArea(poi, adcode) {
	return new Promise((resolve, reject) => {
		// test 实际不需要adcode（因为要查全国的配送范围）
		console.log(adcode)
		getDeliveryOutletsList({
			// adcode: adcode
		}).then((res) => {
			if(res.code == 0){
				let area = getDeliveryArea(res.data.list, {
					lat: poi.lat,
					lng: poi.lng
				})
				if(!area){
					console.log("没有配送站能送到你家")
				}
				resolve(area);
			}else{
				reject(res)
			}
		})
	})
}
// 门店下单： 地址 --> 能配送的网点（含配送范围）
export async function outletSearchShopDeliveryArea(poi, adcode) {
	console.log(adcode)
	return new Promise((resolve, reject) => {
		outletGetDeliveryOutletsList({
			// adcode: adcode
		}).then((res) => {
			if(res.code == 0){
				let area = getDeliveryArea(res.data.list, {
					lat: poi.lat,
					lng: poi.lng
				})
				if(!area){
					console.log("没有配送站能送到你家")
				}
				resolve(area);
			}else{
				reject(res)
			}
		})
	})
}

// 多网点（含配送范围）排序 
export function sortOutletsListByRegion(outletsList, poi) {
  let list = outletsList || [];
  for (let i = 0; i < list.length; i++) {
    list[i].is_cover = 1
    for (let j = 0; j < list[i].delivery_region_list.length; j++) {
      let area = list[i].delivery_region_list[j];
      let pointList = [];
      area.region.split(",").map(el => {
        let t = el.split(' ');
        pointList.push({
          latitude: Number(t[1]),
          longitude: Number(t[0])
        })
      })
      if (_IsPtInPoly(poi.lat, poi.lng, pointList)) {
        if(list[i].is_cover == 2){
          if(list[i].freight > area.freight){
            list[i].freight = area.freight
          }
        }else{
          list[i].is_cover = 2
          list[i].freight = area.freight
        }
      }
    }
  }
  list = list.sort((a, b) => b.is_cover - a.is_cover);

  return list;
}


// 多网点（含配送范围） --> 能配送的网点（含配送范围）
export function getDeliveryArea(outletsList, poi) {
	let list = outletsList || [];
	let dlvrAreaList = [];
	for (let i = 0; i < list.length; i++) {
		for (let j = 0; j < list[i].delivery_region_list.length; j++) {
			let area = list[i].delivery_region_list[j];
				area.outlets_id = list[i].outlets_id
				area.outlets_name = list[i].outlets_name

			let pointList = [];
			area.region.split(",").map(el => {
				let t = el.split(' ');
				pointList.push({
					latitude: Number(t[1]),
					longitude: Number(t[0])
				})
			})
			if (_IsPtInPoly(poi.lat, poi.lng, pointList)) {
				dlvrAreaList.push(area)
			}
		}
	}
	dlvrAreaList = dlvrAreaList.sort((a, b) => a.freight - b.freight);
	return dlvrAreaList[0] || null
}

function _IsPtInPoly(aLat, aLon, pointList) {
	let iSum = 0
	let iCount = pointList.length
	if (iCount < 3) {
		return false
	}

	let pLat1, pLon1, pLat2, pLon2
	for (let i = 0; i < iCount; i++) {
		pLat1 = pointList[i].latitude
		pLon1 = pointList[i].longitude
		if (i == iCount - 1) {
			pLat2 = pointList[0].latitude
			pLon2 = pointList[0].longitude
		} else {
			pLat2 = pointList[i + 1].latitude
			pLon2 = pointList[i + 1].longitude
		}
		if (((aLat >= pLat1) && (aLat < pLat2)) || ((aLat >= pLat2) && (aLat < pLat1))) {
			if (Math.abs(pLat1 - pLat2) > 0) {
				var pLon = pLon1 - ((pLon1 - pLon2) * (pLat1 - aLat)) / (pLat1 - pLat2);
				if (pLon < aLon) {
					iSum += 1
				}
			}
		}
	}

	if (iSum % 2 != 0) {
		return true
	} else {
		return false
	}
}